const removeBodyScroll = (): void => {
  document.body.style.position = 'fixed';
  document.body.style.overflow = 'hidden';
  document.body.style.top = `-${window.scrollY}px`;
  document.body.style.left = `0`;
  document.body.style.right = `0`;
};

const addBodyScroll = (): void => {
  document.body.style.position = '';
  document.body.style.overflow = '';
  document.body.style.top = '';
  document.body.style.left = '';
  document.body.style.right = '';
  window.scrollTo(0, parseInt(document.body.style.top || '0', 10) * -1);
};

export { removeBodyScroll, addBodyScroll };
