import React, { FC } from 'react';
import { Typographies, Typography } from 'src/components/typography/typography';
import { Plan } from 'src/types/plan';

import { DECIMAL_NUMBER } from '../../../../../../utils/currency';

import styles from './order.module.scss';

type Props = {
  plan: Plan;
};

const Order: FC<Props> = ({ plan }) => {
  const { price, trialPrice, trialDuration, currency, planPeriod } = plan;
  return (
    <div className={styles.order}>
      <div className={styles.order__plan}>
        <div className={styles.line}>
          <Typography variant={Typographies.LABEL_MEDIUM} className={styles.text}>
            Your {planPeriod}ly plan:
          </Typography>

          <Typography variant={Typographies.LABEL_MEDIUM} className={styles.text}>
            {Number(price) / DECIMAL_NUMBER} {currency}
          </Typography>
        </div>

        {!!trialDuration && (
          <div className={styles.line}>
            <Typography variant={Typographies.LABEL_MEDIUM} className={styles.text}>
              Your trial period price:
            </Typography>

            <Typography variant={Typographies.LABEL_MEDIUM} className={styles['text--red']}>
              {Number(trialPrice) / DECIMAL_NUMBER} {currency}
            </Typography>
          </div>
        )}
      </div>

      <div className={styles.order__total}>
        <div className={styles.line}>
          <Typography variant={Typographies.TITLE_LARGE}>Total:</Typography>

          <Typography variant={Typographies.TITLE_LARGE}>
            <span>
              {(Number(trialPrice) || Number(price)) / DECIMAL_NUMBER} {currency}
            </span>{' '}
            <Typography variant={Typographies.LABEL_MEDIUM} className={styles.text} tag="span">
              per {planPeriod}
            </Typography>
          </Typography>
        </div>
      </div>

      <div className={styles.order__disclaimer}>
        <Typography variant={Typographies.LABEL_SMALL}>This is an autorenewing subscription.</Typography>

        {/* TODO! Do we use this checkout? Since here we have hardcode*/}
        <Typography variant={Typographies.LABEL_SMALL}>
          Today, Perky will charge you $13.99 as an introductory offer. Afterward, your payment method will be
          automatically charged the full price of $29.84 every month until you cancel the subscription. You can cancel
          the subscription via the Perky app settings or by reaching out to support at{' '}
          {process.env.REACT_APP_COMPANY_EMAIL}
        </Typography>

        <Typography variant={Typographies.LABEL_SMALL}>
          Please note that this subscription is bound by Perky’s{' '}
          <a
            target="_blank"
            href={process.env.REACT_APP_PRIVACY_POLICY}
            rel="noreferrer noopener"
            className={styles.link}
          >
            Privacy Policy
          </a>
          ,{' '}
          <a
            target="_blank"
            href={process.env.REACT_APP_TERMS_OF_SERVICE_LINK}
            rel="noreferrer noopener"
            className={styles.link}
          >
            Terms of Use
          </a>
          , and{' '}
          <a
            target="_blank"
            href={process.env.REACT_APP_REFUND_POLICY_LINK}
            rel="noreferrer noopener"
            className={styles.link}
          >
            Refund Policy
          </a>
          .
        </Typography>
      </div>
    </div>
  );
};

export { Order };
