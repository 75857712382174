import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import { ScrollToTop, ThemeColor } from './components';
import { AnalyticProvider, ModalContextProvider, ToastContextProvider } from './contexts';
import { InitialAnalytics } from './containers/initial-analytics';
import { PaymentSuccessMiddleware } from './middlewares/payment-success/payment-success';
import { RouterProvider } from './routes/RouterProvider';
import { UserTrackingMiddleware } from './middlewares/user-tracking/user-tracking';
import GrowthbookProvider from './services/growthbook/Provider';

function App() {
  return (
    <UserTrackingMiddleware>
      <GrowthbookProvider>
        <AnalyticProvider>
          <BrowserRouter>
            <InitialAnalytics>
              <PaymentSuccessMiddleware>
                <ToastContextProvider>
                  <ModalContextProvider>
                    <ScrollToTop />
                    <ThemeColor />
                    <RouterProvider />
                  </ModalContextProvider>
                </ToastContextProvider>
              </PaymentSuccessMiddleware>
            </InitialAnalytics>
          </BrowserRouter>
        </AnalyticProvider>
      </GrowthbookProvider>
    </UserTrackingMiddleware>
  );
}

export default App;
