import React from 'react';
import clsx from 'clsx';
import { ReactComponent as AppStore } from 'src/assets/icons/app_store.svg';
import { StoreButtons } from 'src/types/common';

import googlePlay from '../../assets/images/google-play.png';

import styles from './stores.module.scss';

type StoresProps = {
  onStoreClick?: (store: StoreButtons) => Promise<void>;
  className?: string;
};

const Stores = ({ onStoreClick, className }: StoresProps) => (
  <ul className={clsx(styles.stores, className)}>
    <li className={styles.store}>
      <a
        className={styles.storeLink}
        href={process.env.REACT_APP_APPLE_STORE}
        onClick={async () => {
          if (onStoreClick) {
            await onStoreClick(StoreButtons.APP_STORE);
          }
        }}
      >
        <AppStore />
      </a>
    </li>

    <li className={styles.store}>
      <a
        className={styles.storeLink}
        href={process.env.REACT_APP_GOOGLE_PLAY}
        onClick={async () => {
          if (onStoreClick) {
            await onStoreClick(StoreButtons.GOOGLE_PLAY);
          }
        }}
      >
        <img src={googlePlay} alt="google play" />
      </a>
    </li>
  </ul>
);

export { Stores };
