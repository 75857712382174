import React, { FC, useEffect, useState } from 'react';

import { getLSValue } from '../../utils/locale-storage';
import { USER_DATA } from '../../constants/common';
import { growthbook } from './growthbook';
import { FullscreenLoader } from '../../components/fullscreen-loader';
import useQueryParams from '../../hooks/use-query-params';

type Props = {
  children: React.ReactNode;
};

const GrowthbookProvider: FC<Props> = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);

  const { getQueryParams } = useQueryParams();

  const userId = (getLSValue(USER_DATA, true)?.user_id as string) || (getQueryParams().customer_id as string) || '';

  useEffect(() => {
    (async (): Promise<void> => {
      try {
        if (!userId) {
          return;
        }

        await growthbook.init({
          user_id: userId,
          device_id: window.navigator.userAgent,
          device_language: window.navigator.language?.toLowerCase() || 'en',
          browser: window.navigator.userAgent,
          url: window.location.host,
        });

        growthbook.startAbTests();
      } finally {
        setIsLoading(false);
      }
    })();
  }, [userId]);

  return <>{isLoading ? <FullscreenLoader /> : children}</>;
};

export default GrowthbookProvider;
