import React, { FC } from 'react';
import { Plan } from 'src/types/plan';

import styles from './disclaimer.module.scss';

const DAYS_IN_WEEK = 7;
const CENTS_IN_DOLLAR = 100;

type Props = {
  plan: Plan;
};

const Disclaimer: FC<Props> = ({ plan }) => (
  <div className={styles.info}>
    {plan.trialDuration ? (
      <>
        You will be charged only {plan?.currencySymbol || '$'}
        {Number(plan?.trialPrice || '99') / CENTS_IN_DOLLAR} for your {plan?.trialDuration || DAYS_IN_WEEK}-day trial.
        Once the trial ends, you will be charged {plan?.currencySymbol || '$'}
        {Number(plan?.price) / CENTS_IN_DOLLAR}/{plan?.planPeriod || 'month'}. Cancel anytime. The charge will appear on
        your bill as IMAGIN8 APP for card payments and WNDR11 LTD for PayPal transactions.
      </>
    ) : (
      <>
        You will be charged{' '}
        <span className={styles.bold}>
          {plan?.currencySymbol || '$'}
          {Number(plan?.price) / CENTS_IN_DOLLAR}/{plan?.planPeriod || 'month'}
        </span>
        . Cancel anytime. The charge will appear on your bill as IMAGIN8 APP for card payments and WNDR11 LTD for PayPal
        transactions.
      </>
    )}
    <br /> {process.env.REACT_APP_COMPANY_ADDRESS} <br /> {process.env.REACT_APP_COMPANY_EMAIL}
  </div>
);

export { Disclaimer };
