/* eslint-disable @typescript-eslint/no-unsafe-call */
import React, { FC, useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Plan } from 'src/types/plan';
import { Typographies, Typography } from 'src/components/typography/typography';
import { ConfigResponse } from 'src/services/web-api';
import { Modal } from 'src/components';

import { PaypalButton, PaypalStyleOption } from '../../components/paypal-button';
import { PaymentMethods } from './types';
import { PaymentMethodButton } from './components/payment-method-button';
import { AppleGooglePayButton } from '../../components/apple-google-pay-button';
import { CardForm } from './components/card-form';
import { usePaymentContext } from '../../payment';
import { Order } from './components/order';
import CloseIcon from '../../../../assets/icons/close_icon.svg';

import styles from './checkout-kegel.module.scss';

const paypalStyleOptions: PaypalStyleOption = {
  shape: 'pill',
  height: 55,
  color: 'gold',
  layout: 'vertical',
  label: 'buynow',
};

type Props = {
  paymentConfig: ConfigResponse;
  plan: Plan;
  onCheckoutClose: () => void;
};

const CheckoutKegel: FC<Props> = ({ paymentConfig, plan, onCheckoutClose }) => {
  const [activeCard, setActiveCard] = useState<PaymentMethods>(PaymentMethods.CARD);
  const [orderElementHeight, setOrderElementHeight] = useState(0);
  const { alternativePayStatus } = usePaymentContext();
  const isAppleGooglePay = alternativePayStatus?.supported?.applePay || alternativePayStatus?.supported?.googlePay;

  const handlePaymentButtonClick = (type: PaymentMethods): void => {
    setActiveCard(type);
  };

  const orderRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (orderRef.current) {
      setOrderElementHeight(orderRef.current.clientHeight);
    }
  }, []);

  return (
    <Modal isOpen onClose={onCheckoutClose} viewType="bottom" disableBackdropClick>
      <div className={styles.container}>
        <div className={styles.header}>
          <Typography variant={Typographies.HEADLINE_SMALL} className={styles.header__title}>
            Select payment method
          </Typography>

          <button onClick={onCheckoutClose} className={styles.header__close}>
            <img src={CloseIcon} alt="" />
          </button>
        </div>

        <div className={styles.form}>
          <Swiper
            slidesPerView={1}
            spaceBetween={16}
            initialSlide={0}
            centeredSlides
            className={styles.slider}
            onSwiper={(swiper: any): void => {
              // eslint-disable-next-line @typescript-eslint/no-unused-expressions,max-len
              activeCard === PaymentMethods.CARD && swiper.slideNext(); // By invoking this method we trigger slider for IOS Chrome
            }}
          >
            <div className={styles.buttons} style={{ paddingBottom: `calc(${orderElementHeight}px + 40px)` }}>
              {[PaymentMethods.PAYPAL, PaymentMethods.CARD].map((type) => (
                <PaymentMethodButton
                  key={type}
                  isActive={activeCard === type}
                  onClick={handlePaymentButtonClick}
                  type={type}
                />
              ))}
            </div>

            <SwiperSlide className={styles.slide}>
              <div className={styles.paypal}>
                <PaypalButton paymentConfig={paymentConfig} plan={plan} styleOptions={paypalStyleOptions} />
              </div>
            </SwiperSlide>

            <SwiperSlide className={styles.slide}>
              {isAppleGooglePay && (
                <div className={styles.apple_google_pay}>
                  <AppleGooglePayButton paymentConfig={paymentConfig} plan={plan} />

                  <Typography variant={Typographies.LABEL_LARGE} className={styles.divider}>
                    <span className={styles.divider__text}>or pay with card</span>
                  </Typography>
                </div>
              )}

              <CardForm paymentConfig={paymentConfig} plan={plan} />
            </SwiperSlide>
          </Swiper>

          <div className={styles.order} ref={orderRef}>
            <Order plan={plan} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export { CheckoutKegel };
