import React, { FC, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import clsx from 'clsx';

import styles from './modal.module.scss';

export interface ModalTypeProps {
  isOpen: boolean;
  children?: React.ReactNode;
  disableBackdropClick?: boolean;
  backdropClassName?: string;
  viewType?: 'full' | 'top' | 'bottom' | 'center';
  onClose(): void;
}

const Modal: FC<ModalTypeProps> = ({
  isOpen,
  disableBackdropClick = false,
  backdropClassName = '',
  viewType = 'center',
  onClose,
  children,
}) => {
  const nodeRef = useRef(null);

  const handleBackdropClick = (e: React.MouseEvent) => {
    if (disableBackdropClick) {
      return;
    }

    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <CSSTransition
      in={isOpen}
      nodeRef={nodeRef}
      timeout={300}
      classNames={{
        enter: styles.modalEnter,
        enterActive: styles.modalEnterActive,
        exit: styles.modalExit,
        exitActive: styles.modalExitActive,
      }}
      unmountOnExit
    >
      <div
        ref={nodeRef}
        className={clsx(styles.backdrop, styles[viewType], backdropClassName, {
          [styles.is_open]: isOpen,
        })}
        onClick={handleBackdropClick}
      >
        <div className={styles.modal}>{children}</div>
      </div>
    </CSSTransition>
  );
};

export { Modal };
