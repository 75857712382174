import {
  GrowthBook as NativeGrowthBook,
  TrackingCallback,
  FeatureResult,
  FeatureDefinition,
} from '@growthbook/growthbook';

class Growthbook {
  instance: null | NativeGrowthBook = null;

  setUp(clientKey: string, trackingCallbacks: TrackingCallback[]): void {
    if (clientKey) {
      this.instance = new NativeGrowthBook({
        apiHost: process.env.REACT_APP_GROWTHBOOK_API_HOST,
        clientKey,
        enableDevMode: process.env.REACT_APP_GROWTHBOOK_DEV_MODE === 'true',
        trackingCallback: (experiment, result): void => {
          trackingCallbacks.forEach((trackingCallback: TrackingCallback): void => {
            trackingCallback(experiment, result);
          });
        },
      });
    }
  }

  async init(attributes: Record<string, any>): Promise<void> {
    if (this.instance) {
      await this.instance.setAttributes(attributes);
      await this.instance.init();
    }
  }

  getAttributes(): { [p: string]: any } | null {
    if (this.instance) {
      return this.instance.getAttributes();
    }

    return null;
  }

  async setAttributes(attributes: Record<string, any>): Promise<void> {
    if (this.instance) {
      await this.instance.setAttributes({
        ...this.getAttributes(),
        ...attributes,
      });
    }
  }

  getFeatureValue<T>(key: string, defaultValue: T): T {
    if (this.instance) {
      return this.instance.getFeatureValue<T>(key, defaultValue) as T;
    }
    return defaultValue;
  }

  evalFeature(key: string): FeatureResult | null {
    if (this.instance) {
      return this.instance.evalFeature(key);
    }

    return null;
  }

  startAbTests(): void {
    if (this.instance) {
      const features = this.instance.getFeatures();

      Object.entries(features).forEach(([key, value]) => {
        this.instance?.getFeatureValue(key, value.defaultValue);
      });
    }
  }

  getFeatures(): Record<string, FeatureDefinition> {
    if (this.instance) {
      return this.instance.getFeatures();
    }

    return {};
  }
}

const growthbook = new Growthbook();

export { growthbook };
