/* eslint-disable @typescript-eslint/no-unsafe-call */
import React, { FC, useState } from 'react';
import 'swiper/css';
import { Plan } from 'src/types/plan';
import { ConfigResponse } from 'src/services/web-api';
import { Modal, Typographies, Typography } from 'src/components';

import { PaypalButton, PaypalStyleOption } from '../../components/paypal-button';
import { usePaymentContext } from '../../payment';
import { Order } from './components/order';
import { Footer } from './components/footer';
import { Header } from './components/header';
import { Accordion } from './components/accordion';
import { ReactComponent as ApplePayIcon } from './assets/applepay.svg';
import { ReactComponent as GooglePayIcon } from './assets/googlepay.svg';
import { ReactComponent as PaypalIcon } from './assets/paypal.svg';
import { ReactComponent as CreditCardsIcon } from './assets/credit_cards.svg';
import { ReactComponent as CheckIcon } from './assets/check.svg';
import { AppleGooglePayButton } from '../../components/apple-google-pay-button';
import SecureSmallImg from './assets/secure_small.webp';
import SecureLargeImg from './assets/secure_large.webp';
import { CardForm } from './components/card-form';

import styles from './checkout-slimkit.module.scss';

const paypalStyleOptions: PaypalStyleOption = {
  shape: 'rect',
  height: 55,
  color: 'gold',
  layout: 'vertical',
  label: 'buynow',
};

type Props = {
  paymentConfig: ConfigResponse;
  plan: Plan;
  onCheckoutClose: () => void;
};

const CheckoutSlimkit: FC<Props> = ({ paymentConfig, plan, onCheckoutClose }) => {
  const { alternativePayStatus } = usePaymentContext();
  const isApplePay = alternativePayStatus?.supported?.applePay;
  const isGooglePay = alternativePayStatus?.supported?.googlePay;

  const [optionIdx, setOptionIdx] = useState(0);

  const handleOptionClick = (index: number) => {
    setOptionIdx(index);
  };

  const options = [
    ...(isApplePay
      ? [
          {
            header: (
              <div className={styles.option__header}>
                <Typography variant={Typographies.LABEL_LARGE} className={styles.option__header_text}>
                  Apple Pay
                </Typography>

                <ApplePayIcon className={styles.option__header_icon} />
              </div>
            ),
            content: (
              <div className={styles.option__content}>
                <div className={styles.option__content_list}>
                  <div className={styles.option__content_list_item}>
                    <CheckIcon />
                    <Typography variant={Typographies.LABEL_MEDIUM}>
                      Easy and private payments with Face/Touch ID
                    </Typography>
                  </div>

                  <div className={styles.option__content_list_item}>
                    <CheckIcon />
                    <Typography variant={Typographies.LABEL_MEDIUM}>
                      Keeps your financial info safe with end-to-end encryption
                    </Typography>
                  </div>

                  <div className={styles.option__content_list_item}>
                    <CheckIcon />
                    <Typography variant={Typographies.LABEL_MEDIUM}>
                      Protected by Apple Pay’s unique Device Account Number
                    </Typography>
                  </div>
                </div>

                <AppleGooglePayButton paymentConfig={paymentConfig} plan={plan} />

                <Typography variant={Typographies.BODY_SMALL} className={styles.option__content_disclaimer}>
                  Your payment information is secure with SSL/TLS encryption
                </Typography>
              </div>
            ),
          },
        ]
      : []),

    ...(isGooglePay
      ? [
          {
            header: (
              <div className={styles.option__header}>
                <Typography variant={Typographies.LABEL_LARGE} className={styles.option__header_text}>
                  Google Pay
                </Typography>

                <GooglePayIcon className={styles.option__header_icon} />
              </div>
            ),
            content: (
              <div className={styles.option__content}>
                <div className={styles.option__content_list}>
                  <div className={styles.option__content_list_item}>
                    <CheckIcon />
                    <Typography variant={Typographies.LABEL_MEDIUM}>
                      Easy and private payments with Face/Touch ID
                    </Typography>
                  </div>

                  <div className={styles.option__content_list_item}>
                    <CheckIcon />
                    <Typography variant={Typographies.LABEL_MEDIUM}>
                      Keeps your financial info safe with end-to-end encryption
                    </Typography>
                  </div>

                  <div className={styles.option__content_list_item}>
                    <CheckIcon />
                    <Typography variant={Typographies.LABEL_MEDIUM}>
                      Protected by Apple Pay’s unique Device Account Number
                    </Typography>
                  </div>
                </div>

                <AppleGooglePayButton paymentConfig={paymentConfig} plan={plan} />

                <Typography variant={Typographies.BODY_SMALL} className={styles.option__content_disclaimer}>
                  Your payment information is secure with SSL/TLS encryption
                </Typography>
              </div>
            ),
          },
        ]
      : []),

    {
      header: (
        <div className={styles.option__header}>
          <Typography variant={Typographies.LABEL_LARGE} className={styles.option__header_text}>
            PayPal
          </Typography>

          <PaypalIcon className={styles.option__header_icon} />
        </div>
      ),
      content: (
        <div className={styles.option__content}>
          <div className={styles.option__content_list}>
            <div className={styles.option__content_list_item}>
              <CheckIcon />
              <Typography variant={Typographies.LABEL_MEDIUM}>Fast, convenient payment option</Typography>
            </div>

            <div className={styles.option__content_list_item}>
              <CheckIcon />
              <Typography variant={Typographies.LABEL_MEDIUM}>
                Your payment information is secure with SSL/TLS encryption
              </Typography>
            </div>

            <div className={styles.option__content_list_item}>
              <CheckIcon />
              <Typography variant={Typographies.LABEL_MEDIUM}>
                Backed by PayPal’s industry-leading fraud protection
              </Typography>
            </div>
          </div>

          <PaypalButton paymentConfig={paymentConfig} plan={plan} styleOptions={paypalStyleOptions} />

          <Typography variant={Typographies.BODY_SMALL} className={styles.option__content_disclaimer}>
            Your payment information is secure with SSL/TLS encryption
          </Typography>

          <img src={SecureSmallImg} alt="" className={styles.option__content_secure_img} />
        </div>
      ),
    },
    {
      header: (
        <div className={styles.option__header}>
          <Typography variant={Typographies.LABEL_LARGE} className={styles.option__header_text}>
            Credit card
          </Typography>

          <CreditCardsIcon className={styles.option__header_icon} />
        </div>
      ),
      content: (
        <div className={styles.option__content}>
          <CardForm paymentConfig={paymentConfig} plan={plan} />

          <Typography variant={Typographies.BODY_SMALL} className={styles.option__content_disclaimer}>
            Your payment information is secure with SSL/TLS encryption
          </Typography>

          <img src={SecureLargeImg} alt="" className={styles.option__content_secure_img} />
        </div>
      ),
    },
  ];

  return (
    <Modal isOpen onClose={onCheckoutClose} viewType="bottom" disableBackdropClick>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.header}>
            <Header onClose={onCheckoutClose} />
          </div>

          <div className={styles.form}>
            {options.map((option, idx) => (
              <div className={styles.option} key={idx}>
                <Accordion
                  header={option.header}
                  content={option.content}
                  isOpen={optionIdx === idx}
                  onClick={() => handleOptionClick(idx)}
                />
              </div>
            ))}
          </div>

          <div className={styles.order}>
            <Order plan={plan} />
          </div>

          <Footer />
        </div>
      </div>
    </Modal>
  );
};

export { CheckoutSlimkit };
