import React, { FC } from 'react';
import clsx from 'clsx';
import cardVisa from 'src/assets/images/card-visa.png';
import mastercard from 'src/assets/images/card-mastercard.png';
import maestro from 'src/assets/images/card-maestro.png';
import discover from 'src/assets/images/card-discover.png';

import styles from './payment-systems.module.scss';

const PaymentSystems: FC = () => (
  <div className={styles.systems}>
    <div className={styles.title}>Pay with card</div>
    <ul className={styles.cards}>
      <li className={styles.card}>
        <img className={clsx(styles.visa, styles.image)} src={cardVisa} alt="Visa" />
      </li>
      <li className={styles.card}>
        <img className={clsx(styles.mastercard, styles.image)} src={mastercard} alt="Mastercard" />
      </li>
      <li className={styles.card}>
        <img className={clsx(styles.maestro, styles.image)} src={maestro} alt="Maestro" />
      </li>
      <li className={styles.card}>
        <img className={clsx(styles.discover, styles.image)} src={discover} alt="Discover" />
      </li>
    </ul>
  </div>
);

export { PaymentSystems };
