import React, { FC, useEffect, useState } from 'react';
import { Button, BUTTON_VARIANTS, Loading } from 'src/components';
import { getCurrency } from 'src/utils/currency';
import { Plan } from 'src/types/plan';

import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';

import { PaymentSystems } from './payment-systems';
import { Disclaimer } from './disclaimer';
import { ConfigResponse } from '../../../../../../services/web-api';
import { usePaymentMethods } from '../../../../hooks/use-payment-methods';

import styles from './card-form.module.scss';

type Props = {
  plan: Plan;
  paymentConfig: ConfigResponse;
};

const CardForm: FC<Props> = ({ plan, paymentConfig }) => {
  const [isLoading, setIsLoading] = useState(false);

  const elements = useElements();
  const stripe = useStripe();

  const { onPaymentError, onPaymentSuccess, onCheckoutSubmit, onPurchaseSubscribe } = usePaymentMethods(plan);

  useEffect(() => {
    if (elements) {
      const element = elements.getElement('payment');
      element?.on('ready', () => {
        setIsLoading(false);
      });
    }
  }, [elements, setIsLoading]);

  const handleCardConfirm = async () => {
    try {
      if (!stripe || !elements) {
        return;
      }
      setIsLoading(true);

      await onCheckoutSubmit('card');
      await elements.submit();

      const purchase = await onPurchaseSubscribe(paymentConfig.checkout.stripe.account_id, 'stripe');

      if (!purchase) {
        throw Error("Couldn't proceed a purchase");
      }

      const result = await stripe.confirmCardPayment(purchase?.stripe?.client_secret || '', {
        payment_method: {
          card: elements.getElement(CardNumberElement) || { token: '' },
          billing_details: {
            address: {
              country: 'GB',
            },
          },
        },
      });

      if (result.error?.type && result.error?.type !== 'validation_error') {
        await onPaymentError({
          code: result.error?.code || '',
          declineCode: result.error?.decline_code || '',
          method: 'card',
        });
        return;
      }

      if (result?.paymentIntent?.status === 'succeeded') {
        await onPaymentSuccess({
          purchaseId: purchase.purchase_id,
          paymentAmount: result?.paymentIntent?.amount || 0,
          method: 'curd',
        });
      }
    } catch (e: unknown) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFormReady = () => {
    setIsLoading(false);
  };

  return (
    <div>
      {isLoading && (
        <div className={styles.loading}>
          <Loading isDark />
        </div>
      )}

      <PaymentSystems />

      <div className={styles.total}>
        Total: <span>{getCurrency(Number(plan.trialPrice) || Number(plan.price), plan.currency)}</span>
      </div>

      <div className={styles.custom_form}>
        <CardNumberElement
          options={{
            classes: {
              base: styles.base_number,
            },
            placeholder: 'Card number',
          }}
          onReady={handleFormReady}
        />
        <CardExpiryElement
          options={{
            classes: {
              base: styles.base_date,
            },
            placeholder: 'MM/YY',
          }}
        />
        <CardCvcElement
          options={{
            classes: {
              base: styles.base_code,
            },
            placeholder: 'CVV',
          }}
        />
      </div>

      <Button
        type="button"
        className={styles.submit_button}
        disabled={isLoading}
        onClick={handleCardConfirm}
        variant={BUTTON_VARIANTS.SECONDARY}
      >
        <div className={styles.loading_text}>{isLoading && <Loading className={styles.loading} />} PAY</div>
      </Button>

      <Disclaimer plan={plan} />
    </div>
  );
};

export { CardForm };
