const P_TRIAL_PAYMENT_LAYOUT = 'p_trial_payment_layout';
const PAYWALL_VERSION = 'paywall_version';
enum PaywallVersionValues {
  v1 = 'v1',
  v2 = 'v2',
  v4 = 'paywall_v4',
  v4NoTrial = 'paywall_v4_no_trial',
  v1nude1 = 'paywall_v1_nude_1',
  v1nude2 = 'paywall_v1_nude_2',
  paywallUndressV1 = 'paywall_undress_v1',
}

const PAYWALL_CTA = 'paywall_cta';
const PAYWALL_CTA_NO_TRIAL = 'paywall_cta_no_trial';
const PAYWALL_SHORT_FLOW_PLANS = 'paywall_short_flow_plans';
const IS_ALLOW_DOWNLOAD_APP_FROM_PAYWALL = 'is_allow_download_app_from_paywall';
const PAYWALL_CTA_ACTION = 'paywall_cta_action';
const PAYWALL_PLANS = 'paywall_plans';
const PAYWALL_PLANS_NO_TRIAL = 'paywall_plans_no_trial';
const PAYMENT_ERROR_DISPLAY = 'payment_error_display';
const CHECKOUT_VERSION = 'checkout_version';
const SUCCESS_PAGE_VERSION = 'success_page_version';
const CLOSE_PAYWALL_ALLOWANCE = 'is_allow_close_paywall';
const BACK_NAVIGATION_VERSION = 'back_navigation_version';
const IS_PWA_FLOW = 'is_pwa_flow';

enum SuccessPageVersion {
  v1 = 'v1',
  v2 = 'success_page_v2',
  v3 = 'success_page_v3',
}

const QUIZ_VERSION = 'quiz_version';
enum QuizVersion {
  v1 = 'v1',
  v3 = 'quiz_v3',
  v4 = 'quiz_v4',
  v5 = 'quiz_v5',
}

const LOADER_VERSION = 'loader_version';
enum LoaderVersion {
  v1 = 'loader_v1',
  v2 = 'loader_v2',
}

const QUIZ_FLOW = 'quiz_flow';
enum QuizFlow {
  payment = 'payment',
  downloadApp = 'download_app',
}

const EMAIL_VERSION = 'email_version';
enum EmailVersion {
  v1 = 'email_v1',
  v2 = 'email_v2',
}

enum BackNavigationVersion {
  v1 = 'back_navigation_v1',
  v2 = 'back_navigation_v2',
}

export {
  P_TRIAL_PAYMENT_LAYOUT,
  PAYWALL_VERSION,
  PaywallVersionValues,
  PAYWALL_PLANS,
  PAYWALL_PLANS_NO_TRIAL,
  PAYWALL_SHORT_FLOW_PLANS,
  PAYWALL_CTA,
  PAYWALL_CTA_NO_TRIAL,
  PAYWALL_CTA_ACTION,
  IS_ALLOW_DOWNLOAD_APP_FROM_PAYWALL,
  PAYMENT_ERROR_DISPLAY,
  CHECKOUT_VERSION,
  SUCCESS_PAGE_VERSION,
  CLOSE_PAYWALL_ALLOWANCE,
  BACK_NAVIGATION_VERSION,
  IS_PWA_FLOW,
  BackNavigationVersion,
  SuccessPageVersion,
  QUIZ_VERSION,
  QuizVersion,
  LOADER_VERSION,
  LoaderVersion,
  QUIZ_FLOW,
  QuizFlow,
  EMAIL_VERSION,
  EmailVersion,
};
