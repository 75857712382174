import React, { FC } from 'react';
import { Typographies, Typography } from 'src/components/typography/typography';
import { Plan } from 'src/types/plan';

import { DECIMAL_NUMBER } from '../../../../../../utils/currency';

import styles from './order.module.scss';

type Props = {
  plan: Plan;
};

const Order: FC<Props> = ({ plan }) => {
  const { price, trialPrice, trialDuration, currency, planPeriod } = plan;
  return (
    <div className={styles.order}>
      <div className={styles.order__plan}>
        <div className={styles.order__line}>
          <Typography variant={Typographies.LABEL_LARGE} className={styles.order__text}>
            Your {planPeriod}ly plan:
          </Typography>
          <Typography variant={Typographies.LABEL_LARGE} className={styles.order__text}>
            {Number(price) / DECIMAL_NUMBER} {currency}
          </Typography>
        </div>

        {!!trialDuration && (
          <div className={styles.order__line}>
            <Typography variant={Typographies.LABEL_LARGE} className={styles.order__text}>
              Your trial period price:
            </Typography>
            <Typography variant={Typographies.LABEL_LARGE} className={styles.order__text_red}>
              {Number(trialPrice) / DECIMAL_NUMBER} {currency}
            </Typography>
          </div>
        )}
      </div>

      <div className={styles.order__total}>
        <div className={styles.order__line}>
          <Typography variant={Typographies.TITLE_LARGE}>Total:</Typography>
          <Typography variant={Typographies.TITLE_LARGE}>
            {(Number(trialPrice) || Number(price)) / DECIMAL_NUMBER} {currency}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export { Order };
