import { growthbook } from './services/growthbook/growthbook';
import { IS_DEV } from './constants/common';

if (process.env.REACT_APP_GROWTHBOOK_CLIENT_KEY) {
  growthbook.setUp(process.env.REACT_APP_GROWTHBOOK_CLIENT_KEY, [
    (experiment, result): void => {
      if (IS_DEV) {
        // eslint-disable-next-line no-console,@typescript-eslint/no-unsafe-assignment
        console.log('[Feature]', { experiment, result: result?.value });
      }
    },
  ]);
}
