/* eslint-disable deprecation/deprecation */
import React, { FC, useRef } from 'react';
import clsx from 'clsx';

import styles from './accordion.module.scss';

type Props = {
  header: JSX.Element;
  content: JSX.Element;
  isOpen: boolean;
  onClick: () => void;
};

const Accordion: FC<Props> = ({ header, content, isOpen, onClick }) => {
  const contentRef = useRef<HTMLDivElement | null>(null);

  return (
    <div className={styles.container}>
      <div className={styles.header} onClick={onClick}>
        <div
          className={clsx(styles.header__radio_button, {
            [styles.is_active]: isOpen,
          })}
        />

        <div className={styles.header__title}>{header}</div>
      </div>

      <div
        ref={contentRef}
        className={styles.content}
        style={isOpen ? { maxHeight: String(contentRef.current?.scrollHeight || 0) + 'px' } : { maxHeight: '0px' }}
      >
        {content}
      </div>
    </div>
  );
};

export { Accordion };
