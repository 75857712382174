/* eslint-disable @typescript-eslint/no-unsafe-call */
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Plan } from 'src/types/plan';
import { Typographies, Typography } from 'src/components/typography/typography';
import { ConfigResponse } from 'src/services/web-api';
import { Modal } from 'src/components';

import { PaypalButton, PaypalStyleOption } from '../../components/paypal-button';
import { PaymentMethods } from './types';
import { PaymentMethodButton } from './components/payment-method-button';
import { CardForm } from './components/card-form';
import { usePaymentContext } from '../../payment';
import { Order } from './components/order';
import { ReactComponent as CloseIcon } from '../../../../assets/icons/close_icon.svg';
import { AppleGooglePayButton } from '../../components/apple-google-pay-button';

import styles from './checkout-zyng.module.scss';

const paypalStyleOptions: PaypalStyleOption = {
  shape: 'rect',
  height: 55,
  color: 'gold',
  layout: 'vertical',
  label: 'buynow',
};

type Props = {
  paymentConfig: ConfigResponse;
  plan: Plan;
  onCheckoutClose: () => void;
};

const CheckoutZyng: FC<Props> = ({ paymentConfig, plan, onCheckoutClose }) => {
  const [activeCard, setActiveCard] = useState<PaymentMethods>(PaymentMethods.CARD);
  const [orderElementHeight, setOrderElementHeight] = useState(0);
  const { alternativePayStatus } = usePaymentContext();
  const isApplePay = alternativePayStatus?.supported?.applePay;
  const isGooglePay = alternativePayStatus?.supported?.googlePay;

  const handlePaymentButtonClick = (type: PaymentMethods): void => {
    setActiveCard(type);
  };

  const orderRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (orderRef.current) {
      setOrderElementHeight(orderRef.current.clientHeight);
    }
  }, []);

  const slides = useMemo(
    () => [
      // eslint-disable-next-line no-nested-ternary
      ...(isApplePay ? [PaymentMethods.APPLE_PAY] : isGooglePay ? [PaymentMethods.GOOGLE_PAY] : []),
      PaymentMethods.CARD,
      PaymentMethods.PAYPAL,
    ],
    [isApplePay, isGooglePay],
  );

  return (
    <Modal isOpen onClose={onCheckoutClose} viewType="bottom" disableBackdropClick>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.header}>
            <Typography variant={Typographies.HEADLINE_SMALL} className={styles.header__title}>
              Select a payment method
            </Typography>

            <button onClick={onCheckoutClose} className={styles.header__close}>
              <CloseIcon />
            </button>
          </div>

          <div className={styles.form}>
            <Swiper
              slidesPerView={1}
              spaceBetween={16}
              initialSlide={0}
              centeredSlides
              className={styles.slider}
              onSwiper={(swiper: any): void => {
                // eslint-disable-next-line @typescript-eslint/no-unused-expressions,max-len
                activeCard === PaymentMethods.CARD && swiper.slideTo(slides.indexOf(activeCard)); // By invoking this method we trigger slider for IOS Chrome
              }}
            >
              <div className={styles.buttons} style={{ paddingBottom: `calc(${orderElementHeight}px + 40px)` }}>
                {slides.map((type) => (
                  <PaymentMethodButton
                    key={type}
                    isActive={activeCard === type}
                    onClick={handlePaymentButtonClick}
                    type={type}
                    slides={slides}
                  />
                ))}
              </div>

              {(isApplePay || isGooglePay) && (
                <SwiperSlide className={styles.slide}>
                  <AppleGooglePayButton paymentConfig={paymentConfig} plan={plan} />
                </SwiperSlide>
              )}

              <SwiperSlide className={styles.slide}>
                <CardForm paymentConfig={paymentConfig} plan={plan} />
              </SwiperSlide>

              <SwiperSlide className={styles.slide}>
                <PaypalButton paymentConfig={paymentConfig} plan={plan} styleOptions={paypalStyleOptions} />
              </SwiperSlide>
            </Swiper>

            <div className={styles.order} ref={orderRef}>
              <Order plan={plan} />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export { CheckoutZyng };
