import React, { FC } from 'react';
import { Button, BUTTON_VARIANTS, Typographies, Typography } from 'src/components';

import GirlImg from './assets/girl.webp';
import { ErrorCodesGroup } from '../../helpers/get-payment-error';

import styles from './error-popup.module.scss';

type Props = {
  errorCodeType: ErrorCodesGroup;
  price: number;
  onClose: () => void;
};

const Decline: FC<{ onOpenPaypal: () => void; onSwitchCard: () => void }> = ({ onOpenPaypal, onSwitchCard }) => (
  <div className={styles.main__solution}>
    <Typography variant={Typographies.HEADLINE_MEDIUM} className={styles.main__solution_title}>
      Ooops! Payment declined!
    </Typography>

    <Typography variant={Typographies.TITLE_MEDIUM} className={styles.main__solution_text}>
      Please, contact your bank or use another payment method
    </Typography>

    <div className={styles.main__solution_buttons}>
      <Button variant={BUTTON_VARIANTS.SECONDARY} onClick={onOpenPaypal}>
        Open PayPal
      </Button>
      <Button variant={BUTTON_VARIANTS.PRIMARY} onClick={onSwitchCard}>
        Switch Card
      </Button>
    </div>
  </div>
);

const InsufficientFunds: FC<{ price: number; onOpenPaypal: () => void; onSwitchCard: () => void }> = ({
  price,
  onOpenPaypal,
  onSwitchCard,
}) => (
  <div className={styles.main__solution}>
    <Typography variant={Typographies.HEADLINE_MEDIUM} className={styles.main__solution_title}>
      Ooops! Not enough funds!
    </Typography>

    <Typography variant={Typographies.TITLE_MEDIUM} className={styles.main__solution_total}>
      (for total: ${price})
    </Typography>

    <Typography variant={Typographies.TITLE_MEDIUM} className={styles.main__solution_text}>
      Please, use another payment method, or top up your current card
    </Typography>

    <div className={styles.main__solution_buttons}>
      <Button variant={BUTTON_VARIANTS.SECONDARY} onClick={onOpenPaypal}>
        Open PayPal
      </Button>
      <Button variant={BUTTON_VARIANTS.PRIMARY} onClick={onSwitchCard}>
        Switch Card
      </Button>
    </div>
  </div>
);

const IncorrectDetails: FC<{ onRetry: () => void }> = ({ onRetry }) => (
  <div className={styles.main__solution}>
    <Typography variant={Typographies.HEADLINE_MEDIUM} className={styles.main__solution_title}>
      Ooops! Wrong card details
    </Typography>

    <Typography variant={Typographies.TITLE_MEDIUM} className={styles.main__solution_text}>
      Please, contact your bank or use another payment method
    </Typography>

    <div className={styles.main__solution_buttons}>
      <Button variant={BUTTON_VARIANTS.SECONDARY} onClick={onRetry}>
        Retry
      </Button>
    </div>
  </div>
);

const getErrorSolution = (price: number, onClose: () => void) => {
  const onRetry = (): void => {
    onClose();
  };

  const onOpenPaypal = (): void => {
    const button = document.getElementById('payment_button_paypal');

    if (button) {
      onClose();
      button.click();
      return;
    }

    onClose();
  };

  const onSwitchCard = (): void => {
    const button = document.getElementById('payment_button_card');

    if (button) {
      onClose();
      button.click();
      return;
    }

    onClose();
  };

  return {
    [ErrorCodesGroup.DECLINE]: <Decline onOpenPaypal={onOpenPaypal} onSwitchCard={onSwitchCard} />,
    [ErrorCodesGroup.RETRYABLE]: <Decline onOpenPaypal={onOpenPaypal} onSwitchCard={onSwitchCard} />,
    [ErrorCodesGroup.INSUFFICIENT_FUNDS]: (
      <InsufficientFunds price={price} onOpenPaypal={onOpenPaypal} onSwitchCard={onSwitchCard} />
    ),
    [ErrorCodesGroup.INCORRECT_DETAILS]: <IncorrectDetails onRetry={onRetry} />,
  };
};

const ErrorPopup: FC<Props> = ({ errorCodeType, price, onClose }) => (
  <div className={styles.container}>
    <div className={styles.header}>
      <img className={styles.header__image} src={GirlImg} alt="" />
    </div>

    <div className={styles.content}>
      <div className={styles.main}>
        {getErrorSolution(price, onClose)[errorCodeType]}

        <Typography variant={Typographies.BODY_SMALL} className={styles.main__support}>
          If you have any difficulties solving this problem,
          <br />
          do not hesitate and contact us at{' '}
          <a href={`mailto:${process.env.REACT_APP_COMPANY_EMAIL}`} className={styles.main__link}>
            {process.env.REACT_APP_COMPANY_EMAIL}
          </a>
        </Typography>
      </div>
    </div>
  </div>
);

export { ErrorPopup };
