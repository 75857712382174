/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import { Modal, Title } from 'src/components';
import { ConfigResponse } from 'src/services/web-api';
import { Plan } from 'src/types/plan';

import { AppleGooglePayButton } from '../../components/apple-google-pay-button';
import { PaypalButton } from '../../components/paypal-button';
import { CardForm } from './components/card-form';
import { usePaymentContext } from '../../payment';
import CloseIcon from '../../../../assets/icons/close_icon.svg';

import styles from './checkout-v1.module.scss';

type VariantAProps = {
  paymentConfig: ConfigResponse;
  plan: Plan;
  onCheckoutClose: () => void;
};

const CheckoutV1 = ({ paymentConfig, plan, onCheckoutClose }: VariantAProps) => {
  const { alternativePayStatus } = usePaymentContext();

  const isAppleGooglePay = alternativePayStatus?.supported?.applePay || alternativePayStatus?.supported?.googlePay;

  return (
    <Modal isOpen onClose={onCheckoutClose} viewType="full" backdropClassName={styles.backdrop}>
      <div className={styles.container}>
        <button onClick={onCheckoutClose} className={styles.close}>
          <img src={CloseIcon} alt="" />
        </button>

        <Title className={styles.title} title="Select a secure" secondPartTitle="payment method" isSmall />

        <div className={styles.alternative}>
          {paymentConfig && <PaypalButton paymentConfig={paymentConfig} plan={plan} />}

          {isAppleGooglePay && (
            <div className={styles.apple_google_pay}>
              <AppleGooglePayButton paymentConfig={paymentConfig} plan={plan} />
            </div>
          )}
        </div>

        <div className={styles.divider}>or</div>

        <CardForm paymentConfig={paymentConfig} plan={plan} />
      </div>
    </Modal>
  );
};

export { CheckoutV1 };
