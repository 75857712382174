import React, { FC, useEffect, useMemo, useState } from 'react';
import { getLSValue } from 'src/utils/locale-storage';
import { USER_DATA } from 'src/constants/common';
import { UserLocalStorageData } from 'src/types/user';
import { Plan } from 'src/types/plan';
import { ConfigResponse } from 'src/services/web-api';

import { CreateSubscriptionActions } from '@paypal/paypal-js/types/components/buttons';
import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js';

import { usePaymentMethods } from '../../hooks/use-payment-methods';

type PaypalStyleOption = {
  color?: 'gold' | 'blue' | 'silver' | 'white' | 'black';
  disableMaxWidth?: boolean;
  height?: number; // between 25px and 55px
  label?: 'paypal' | 'checkout' | 'buynow' | 'pay' | 'installment' | 'subscribe' | 'donate';
  layout?: 'vertical' | 'horizontal';
  shape?: 'rect' | 'pill';
  tagline?: boolean;
};

type PaypalButtonProps = {
  paymentConfig: ConfigResponse;
  plan: Plan;
  styleOptions?: PaypalStyleOption;
};

const DEFAULT_STYLE_OPTIONS: PaypalStyleOption = {
  shape: 'rect',
  height: 55,
  color: 'gold',
  layout: 'vertical',
  label: 'paypal',
};

const PaypalButton: FC<PaypalButtonProps> = ({ paymentConfig, plan, styleOptions = DEFAULT_STYLE_OPTIONS }) => {
  const currentUserData = getLSValue(USER_DATA, true) as UserLocalStorageData;
  const [paypalPurchaseId, setPaypalPurchaseId] = useState('');

  const { onPaymentError, onPaymentSuccess, onCheckoutSubmit, onPurchaseSubscribe } = usePaymentMethods(plan);

  useEffect(() => {
    (async () => {
      try {
        if (!currentUserData?.user_id || !paymentConfig?.checkout || paypalPurchaseId) {
          return;
        }

        if (!currentUserData?.email) {
          throw Error('User email is missed for paypal init');
        }

        const purchase = await onPurchaseSubscribe(paymentConfig?.checkout.paypal.account_id, 'paypal');

        if (purchase) {
          setPaypalPurchaseId(purchase.purchase_id);
        }
      } catch (error: unknown) {
        console.error(error);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUserData?.user_id, paymentConfig?.checkout, paypalPurchaseId, currentUserData?.email]);

  const handlePaypalClick = async () => {
    await onCheckoutSubmit('paypal');
  };

  const handlePaypalSuccess = async () => {
    await onPaymentSuccess({ purchaseId: paypalPurchaseId, method: 'paypal' });
  };

  const handlePaypalFailed = async (error: Record<string, unknown>) => {
    const errorMessage = typeof error?.message === 'string' ? error.message : JSON.stringify(error) || '';
    await onPaymentError({ code: errorMessage, method: 'paypal' });
  };

  // We need this key to overcome PayPal SDK's caching actions.subscription.create config,
  const paypalKey = useMemo(
    () => `${currentUserData.user_id}-${paypalPurchaseId}-${plan?.paypalPlanKey}`,
    [currentUserData.user_id, paypalPurchaseId, plan?.paypalPlanKey],
  );

  return (
    <>
      {paypalPurchaseId && (
        <PayPalScriptProvider
          options={{
            intent: 'subscription',
            vault: true,
            disableFunding: 'card',
            clientId: paymentConfig.checkout.paypal.client_id,
          }}
        >
          <PayPalButtons
            key={paypalKey}
            createSubscription={async (data: Record<string, unknown>, actions: CreateSubscriptionActions) =>
              actions.subscription.create({
                // eslint-disable-next-line @typescript-eslint/naming-convention
                plan_id: plan.paypalPlanKey,
                custom_id: `${process.env.REACT_APP_NAME_APP}:${currentUserData.user_id}:${paypalPurchaseId}`,
              })
            }
            onClick={handlePaypalClick}
            onApprove={handlePaypalSuccess}
            onError={handlePaypalFailed}
            style={{ ...styleOptions }}
          />
        </PayPalScriptProvider>
      )}
    </>
  );
};

export { PaypalButton, type PaypalStyleOption };
