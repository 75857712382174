type PaymentConfig = {
  stripePublishableKey: string;
  paypalClientId: string;
  fetched: boolean;
};

enum StoreButtons {
  PWA = 'pwa',
  APP_STORE = 'appstore',
  GOOGLE_PLAY = 'googleplay',
}

export { type PaymentConfig, StoreButtons };
