import React, { FC, useEffect, useRef, useState } from 'react';
import { Button, BUTTON_VARIANTS, Loading } from 'src/components';
import { useAnalytic } from 'src/contexts/index';

import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { StripeElementChangeEvent } from '@stripe/stripe-js/dist/stripe-js/elements/base';

import { Plan } from '../../../../../../types/plan';
import { ConfigResponse } from '../../../../../../services/web-api';
import { usePaymentMethods } from '../../../../hooks/use-payment-methods';
import { Typographies, Typography } from '../../../../../../components/typography/typography';

import styles from './card-form.module.scss';

const inputStyles = {
  color: '#000000',
  fontWeight: '700',
  fontFamily: 'Space Grotesk, sans-serif',
  fontSize: '12px',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '::placeholder': {
    color: '#00000066',
  },
};

type CardPaymentCompletionType = {
  cardNumber: boolean;
  cardCvc: boolean;
  cardExpiry: boolean;
};

type Props = {
  plan: Plan;
  paymentConfig: ConfigResponse;
};

const CardForm: FC<Props> = ({ plan, paymentConfig }) => {
  const [isLoading, setIsLoading] = useState(false);

  const [cardPaymentCompletion, setCardPaymentCompletion] = useState<CardPaymentCompletionType>({
    cardNumber: false,
    cardCvc: false,
    cardExpiry: false,
  });

  const elements = useElements();
  const stripe = useStripe();

  const { trackFBEvent } = useAnalytic();

  const changeEventSent = useRef(false);

  const { onPaymentError, onPaymentSuccess, onCheckoutSubmit, onPurchaseSubscribe } = usePaymentMethods(plan);

  useEffect(() => {
    if (elements) {
      const element = elements.getElement('payment');
      element?.on('ready', () => {
        setIsLoading(false);
      });
    }
  }, [elements, setIsLoading]);

  const handleCardConfirm = async () => {
    try {
      if (!stripe || !elements) {
        return;
      }
      setIsLoading(true);

      await onCheckoutSubmit('card');
      await elements.submit();

      const purchase = await onPurchaseSubscribe(paymentConfig.checkout.stripe.account_id, 'stripe');

      if (!purchase) {
        throw Error("Couldn't proceed a purchase");
      }

      const result = await stripe.confirmCardPayment(purchase?.stripe?.client_secret || '', {
        payment_method: {
          card: elements.getElement(CardNumberElement) || { token: '' },
          billing_details: {
            address: {
              country: 'GB',
            },
          },
        },
      });

      if (result.error?.type && result.error?.type !== 'validation_error') {
        await onPaymentError({
          code: result.error?.code || '',
          declineCode: result.error?.decline_code || '',
          method: 'card',
        });
        return;
      }

      if (result?.paymentIntent?.status === 'succeeded') {
        await onPaymentSuccess({
          purchaseId: purchase.purchase_id,
          paymentAmount: result?.paymentIntent?.amount || 0,
          method: 'card',
        });
      }
    } catch (e: unknown) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePaymentElementChange = async (e: StripeElementChangeEvent) => {
    setCardPaymentCompletion((prev) => ({
      ...prev,
      [e.elementType]: e.complete,
    }));

    if (!changeEventSent.current) {
      return;
    }

    changeEventSent.current = true;

    await trackFBEvent({ name: 'AddPaymentInfo' });
  };

  const handleFormReady = () => {
    setIsLoading(false);
  };

  return (
    <div>
      {isLoading && (
        <div className={styles.loading}>
          <Loading isDark />
        </div>
      )}

      <div className={styles.form}>
        <div className={styles.form__fields}>
          <div>
            <Typography variant={Typographies.LABEL_MEDIUM} className={styles.form__label}>
              Card number
            </Typography>

            <CardNumberElement
              options={{
                showIcon: true,
                classes: {
                  base: styles.form__input,
                },
                style: { base: inputStyles },
                placeholder: 'XXXX XXXX XXXX XXXX',
              }}
              onChange={handlePaymentElementChange}
              onReady={handleFormReady}
            />
          </div>

          <div>
            <Typography variant={Typographies.LABEL_MEDIUM} className={styles.form__label}>
              Expiry (MM/YY)
            </Typography>

            <CardExpiryElement
              options={{
                classes: {
                  base: styles.form__input,
                },
                style: { base: inputStyles },
                placeholder: 'MM/YY',
              }}
              onChange={handlePaymentElementChange}
            />
          </div>

          <div>
            <Typography variant={Typographies.LABEL_MEDIUM} className={styles.form__label}>
              CVV
            </Typography>

            <CardCvcElement
              options={{
                classes: {
                  base: styles.form__input,
                },
                style: { base: inputStyles },

                placeholder: '•••',
              }}
              onChange={handlePaymentElementChange}
            />
          </div>
        </div>

        <Button
          type="button"
          className={styles.form__button}
          disabled={isLoading || Object.values(cardPaymentCompletion).some((el) => !el)}
          isLoading={isLoading}
          onClick={handleCardConfirm}
          variant={BUTTON_VARIANTS.SECONDARY}
        >
          Pay
        </Button>
      </div>
    </div>
  );
};

export { CardForm };
