/* eslint-disable max-len */
import React, { FC } from 'react';
import { Typographies, Typography } from 'src/components/typography/typography';
import { Plan } from 'src/types/plan';

import { ReactComponent as CircleCheckIcon } from '../../assets/circle_check.svg';
import { DECIMAL_NUMBER } from '../../../../../../utils/currency';

import styles from './order.module.scss';

const periods = {
  week: 7,
  month: 30,
  quarter: 90,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'half year': 183,
  year: 365,
};

const calculateRebillDateByPeriod = (period: keyof typeof periods) => {
  const daysToAdd = periods[period];

  if (!daysToAdd) {
    return;
  }

  const currentDate = new Date();
  const newDate = new Date(currentDate);
  newDate.setDate(currentDate.getDate() + daysToAdd);

  return newDate.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
};

type Props = {
  plan: Plan;
};

const Order: FC<Props> = ({ plan }) => {
  const { price, currencySymbol, planPeriod } = plan;

  const bullets = [
    `Your subscription lasts until ${
      calculateRebillDateByPeriod(planPeriod as keyof typeof periods) || 'subscription period'
    }`,
    'You may cancel anytime without charge at least 24 hours before the expiration of the subscription period',
    `If you don’t cancel, your membership will automatically renew at the end of your 1 ${planPeriod} subscription period. A subscription fee of ${currencySymbol}${
      Number(price) / DECIMAL_NUMBER
    } will be charged until you cancel the auto-renewal.`,
    'We don’t currently offer refunds or credits for partial memberships.',
  ];

  const points = [
    `1 ${planPeriod} for ${currencySymbol}${Number(price) / DECIMAL_NUMBER}`,
    'You will get an email confirmation every time your subscription renews.',
  ];

  return (
    <div className={styles.container}>
      <div className={styles.bullets__list}>
        {bullets.map((el, idx) => (
          <div key={idx} className={styles.bullets__item}>
            <div className={styles.bullets__dot} />

            <Typography variant={Typographies.LABEL_MEDIUM} className={styles.bullets__text}>
              {el}
            </Typography>
          </div>
        ))}
      </div>

      <div className={styles.points__list}>
        {points.map((el, idx) => (
          <div key={idx} className={styles.points__item}>
            <CircleCheckIcon className={styles.points__icon} />

            <Typography variant={Typographies.LABEL_MEDIUM} className={styles.points__text}>
              {el}
            </Typography>
          </div>
        ))}
      </div>

      <Typography variant={Typographies.BODY_SMALL} className={styles.total}>
        Total price: {currencySymbol}
        {Number(price) / DECIMAL_NUMBER}
      </Typography>

      <Typography variant={Typographies.LABEL_SMALL} className={styles.disclaimer}>
        To avoid any disruption, you agree that the subscription you selected will automatically be extended for a
        successive renewal period of 1 {planPeriod} and at the full not discounted, price of {currencySymbol}
        {Number(price) / DECIMAL_NUMBER}. To learn more visit our{' '}
        <a
          target="_blank"
          href={process.env.REACT_APP_TERMS_OF_SERVICE_LINK}
          rel="noreferrer noopener"
          className={styles.link}
        >
          terms of use.
        </a>
      </Typography>
    </div>
  );
};

export { Order };
