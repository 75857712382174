import React, { FC } from 'react';
import { Typographies, Typography } from 'src/components';
import { ReactComponent as CloseIcon } from 'src/assets/icons/close_icon.svg';

import LogoImg from '../../assets/logo.webp';

import styles from './header.module.scss';

type Props = {
  onClose: () => void;
};

const Header: FC<Props> = ({ onClose }) => (
  <div className={styles.header}>
    <img src={LogoImg} alt="" className={styles.header__logo} />

    <Typography variant={Typographies.HEADLINE_SMALL} className={styles.header__title}>
      Select a payment method
    </Typography>

    <button onClick={onClose} className={styles.header__close}>
      <CloseIcon />
    </button>
  </div>
);

export { Header };
