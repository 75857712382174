/* eslint-disable @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-assignment */
import React, { FC } from 'react';
import clsx from 'clsx';
import { useSwiper } from 'swiper/react';

import { ReactComponent as CardsIcon } from '../../assets/credit_cards.svg';
import { ReactComponent as PaypalIcon } from '../../assets/paypal.svg';
import { PaymentMethods } from '../../types';

import styles from './payment-method-button.module.scss';

interface Props {
  isActive: boolean;
  onClick: (type: PaymentMethods) => void;
  type: PaymentMethods;
}

const PaymentMethodButton: FC<Props> = ({ isActive, onClick, type }: Props) => {
  const swiper = useSwiper();

  const handleClick = (): void => {
    onClick(type);
    if (type === PaymentMethods.PAYPAL) {
      swiper.slidePrev();
    } else {
      swiper.slideNext();
    }
  };

  return (
    <button
      type="button"
      className={clsx(styles.card, {
        [styles.is_active]: isActive,
      })}
      onClick={handleClick}
      id={`payment_button_${type}`}
    >
      {type === PaymentMethods.CARD ? <CardsIcon /> : <PaypalIcon />}
    </button>
  );
};

export { PaymentMethodButton };
