import React, { FC } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { getLSValue } from '../utils/locale-storage';
import { USER_DATA } from '../constants/common';
import { UserLocalStorageData } from '../types/user';
import { RouterBuilder } from './RouterBuilder';

interface Props {
  Component: FC;
}

export const ProtectedRoute: FC<Props> = ({ Component }) => {
  const userData = (getLSValue(USER_DATA, true) as UserLocalStorageData) || {};
  const location = useLocation();

  if (!userData.email) {
    return <Navigate to={`${RouterBuilder.getDefaultPublicRoutePath()}${location.search}`} replace />;
  }

  return <Component />;
};
