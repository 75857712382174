/* eslint-disable @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-assignment */
import React, { FC, ReactElement } from 'react';
import clsx from 'clsx';
import { useSwiper } from 'swiper/react';

import { ReactComponent as CardsIcon } from '../../assets/credit_cards.svg';
import { ReactComponent as PaypalIcon } from '../../assets/paypal.svg';
import { ReactComponent as ApplePayIcon } from '../../assets/applepay.svg';
import { PaymentMethods } from '../../types';

import styles from './payment-method-button.module.scss';

const ButtonIcon: Record<PaymentMethods, ReactElement> = {
  [PaymentMethods.APPLE_PAY]: <ApplePayIcon />,
  [PaymentMethods.GOOGLE_PAY]: <ApplePayIcon />,
  [PaymentMethods.CARD]: <CardsIcon />,
  [PaymentMethods.PAYPAL]: <PaypalIcon />,
};

interface Props {
  isActive: boolean;
  onClick: (type: PaymentMethods) => void;
  type: PaymentMethods;
  slides: PaymentMethods[];
}

const PaymentMethodButton: FC<Props> = ({ isActive, onClick, type, slides }: Props) => {
  const swiper = useSwiper();

  const handleClick = (): void => {
    onClick(type);

    const slideIndex = slides.indexOf(type);
    swiper.slideTo(slideIndex);
  };

  return (
    <button
      type="button"
      className={clsx(styles.button, {
        [styles.is_active]: isActive,
      })}
      onClick={handleClick}
    >
      {type === PaymentMethods.CARD && <span className={styles.button__text}>Credit Card</span>}
      <div className={styles.button__icon}>{ButtonIcon[type]}</div>
    </button>
  );
};

export { PaymentMethodButton };
