import { FC, ReactNode, useEffect, useState } from 'react';
import { growthbook } from 'src/services/growthbook/growthbook';

import Adjust from '@adjustcom/adjust-web-sdk';

import { useAnalytic } from '../contexts';
import { USER_DATA } from '../constants/common';
import { useGtagClientId } from '../hooks/use-gtag-client-id';
import { getLSValue, setLSValue } from '../utils/locale-storage';
import { UserLocalStorageData } from '../types/user';
import useQueryParams from '../hooks/use-query-params';
import { PAYWALL_PLANS, PAYWALL_PLANS_NO_TRIAL, PAYWALL_VERSION } from '../services/growthbook/features';

const AB_START = 'ab_start';
const NO_TRIAL_ENDING = 'no_trial';
const CUSTOM_PLANS_ID_KEY = 'plan_price_id';

const getFeatureUserProperties = () => {
  const excludedKeys = new Set([PAYWALL_PLANS_NO_TRIAL, PAYWALL_PLANS]);

  const featureUserProperties = Object.keys(growthbook.getFeatures())
    .filter((key) => !excludedKeys.has(key))
    .reduce<Record<string, unknown>>((acc, key) => {
      const feature = growthbook.evalFeature(key);
      if (feature) {
        acc[key] = feature.value?.amplitude_key || feature.value;
      }
      return acc;
    }, {});

  const paywallVersion = featureUserProperties[PAYWALL_VERSION];

  const selectedPaywallKey =
    typeof paywallVersion === 'string' && paywallVersion.includes(NO_TRIAL_ENDING)
      ? PAYWALL_PLANS_NO_TRIAL
      : PAYWALL_PLANS;

  featureUserProperties[CUSTOM_PLANS_ID_KEY] = growthbook.evalFeature(selectedPaywallKey)?.value?.amplitude_key;

  return featureUserProperties;
};

type Props = {
  children: ReactNode;
};

const InitialAnalytics: FC<Props> = ({ children }) => {
  const userLSData = getLSValue(USER_DATA, true) as UserLocalStorageData;
  const [isLoading, setIsLoading] = useState(true);

  const { setUserProperties, sendAmplitudeEvent, amplitudeInit } = useAnalytic();

  const { gtagClientId } = useGtagClientId();
  const { getQueryParams } = useQueryParams();

  useEffect(() => {
    (async () => {
      if (!amplitudeInit || !gtagClientId || !userLSData?.user_id) {
        return;
      }

      try {
        const featureUserProperties = getFeatureUserProperties();
        const webUuid = Adjust.getWebUUID() || '';
        const queryParams = getQueryParams();

        setUserProperties({
          ...featureUserProperties,
          customer_id: userLSData.user_id,
          web_uuid: webUuid,
          campaign: queryParams?.utm_campaign || 'none',
          content: queryParams?.utm_content || 'none',
          source: queryParams?.utm_source || 'none',
          lang: queryParams?.utm_lang || 'none',
        });

        if (gtagClientId) {
          await growthbook.setAttributes({
            client_id: gtagClientId,
          });
        }

        if (getLSValue(AB_START, true)) {
          return;
        }

        sendAmplitudeEvent({
          name: AB_START,
        });

        setLSValue(AB_START, true);
      } catch (error: unknown) {
        console.error('Failed to send initial analytics', error);
      } finally {
        setIsLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amplitudeInit, gtagClientId, userLSData?.user_id]);

  if (isLoading) {
    return null;
  }

  return <>{children}</>;
};

export { InitialAnalytics };
