import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { Button, BUTTON_VARIANTS, Input, Loading } from 'src/components';
import clsx from 'clsx';

import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { StripeElementChangeEvent } from '@stripe/stripe-js/dist/stripe-js/elements/base';

import { Plan } from '../../../../../../types/plan';
import { ConfigResponse } from '../../../../../../services/web-api';
import { usePaymentMethods } from '../../../../hooks/use-payment-methods';

import styles from './card-form.module.scss';

const inputStyles = {
  color: '#000000',
  fontWeight: '400',
  fontFamily: 'Space Grotesk, sans-serif',
  fontSize: '14px',
};

type CardPaymentCompletionType = {
  cardNumber: boolean;
  cardCvc: boolean;
  cardExpiry: boolean;
};

type Props = {
  plan: Plan;
  paymentConfig: ConfigResponse;
};

const CardForm: FC<Props> = ({ plan, paymentConfig }) => {
  const [isLoading, setIsLoading] = useState(false);

  const [cardNameQuery, setCardNameQuery] = useState('');
  const [cardPaymentCompletion, setCardPaymentCompletion] = useState<CardPaymentCompletionType>({
    cardNumber: false,
    cardCvc: false,
    cardExpiry: false,
  });

  const elements = useElements();
  const stripe = useStripe();

  const { onPaymentError, onPaymentSuccess, onCheckoutSubmit, onPurchaseSubscribe } = usePaymentMethods(plan);

  useEffect(() => {
    if (elements) {
      const element = elements.getElement('payment');
      element?.on('ready', () => {
        setIsLoading(false);
      });
    }
  }, [elements, setIsLoading]);

  const handleCardConfirm = async () => {
    try {
      if (!stripe || !elements) {
        return;
      }
      setIsLoading(true);

      await onCheckoutSubmit('card');
      await elements.submit();

      const purchase = await onPurchaseSubscribe(paymentConfig.checkout.stripe.account_id, 'stripe');

      if (!purchase) {
        throw Error("Couldn't proceed a purchase");
      }

      const result = await stripe.confirmCardPayment(purchase?.stripe?.client_secret || '', {
        payment_method: {
          card: elements.getElement(CardNumberElement) || { token: '' },
          billing_details: {
            address: {
              country: 'GB',
            },
          },
        },
      });

      if (result.error?.type && result.error?.type !== 'validation_error') {
        await onPaymentError({
          code: result.error?.code || '',
          declineCode: result.error?.decline_code || '',
          method: 'card',
        });
        return;
      }

      if (result?.paymentIntent?.status === 'succeeded') {
        await onPaymentSuccess({
          purchaseId: purchase.purchase_id,
          paymentAmount: result?.paymentIntent?.amount || 0,
          method: 'card',
        });
      }
    } catch (e: unknown) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePaymentElementChange = (e: StripeElementChangeEvent) => {
    setCardPaymentCompletion((prev) => ({
      ...prev,
      [e.elementType]: e.complete,
    }));
  };

  const handleCardNameChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setCardNameQuery(e.target.value);
  };

  const handleFormReady = () => {
    setIsLoading(false);
  };

  return (
    <div>
      {isLoading && (
        <div className={styles.loading}>
          <Loading isDark />
        </div>
      )}

      <div className={styles.form}>
        <div className={styles.form__fields}>
          <CardNumberElement
            options={{
              showIcon: true,
              classes: {
                base: clsx(styles.form__input, styles.card_number),
              },
              style: { base: inputStyles },
              placeholder: 'Card Number',
            }}
            onChange={handlePaymentElementChange}
            onReady={handleFormReady}
          />

          <CardExpiryElement
            options={{
              classes: {
                base: clsx(styles.form__input, styles.card_expiry),
              },
              style: { base: inputStyles },
              placeholder: 'MM/YY',
            }}
            onChange={handlePaymentElementChange}
          />

          <CardCvcElement
            options={{
              classes: {
                base: clsx(styles.form__input, styles.card_cvc),
              },
              style: { base: inputStyles },

              placeholder: 'CVV',
            }}
            onChange={handlePaymentElementChange}
          />

          <Input
            value={cardNameQuery}
            className={clsx(styles.form__input, styles.card_name)}
            placeholder="Name on Card"
            onChange={handleCardNameChange}
          />
        </div>

        <Button
          type="button"
          className={styles.form__button}
          disabled={isLoading || Object.values(cardPaymentCompletion).some((el) => !el) || !cardNameQuery}
          isLoading={isLoading}
          onClick={handleCardConfirm}
          variant={BUTTON_VARIANTS.SECONDARY}
        >
          Continue
        </Button>
      </div>
    </div>
  );
};

export { CardForm };
