import { PaymentRequestOptions, StripeElementsOptions } from '@stripe/stripe-js';

import { Plan } from '../../../types/plan';

type PurchaseOptionsConfigType = {
  billingId: string;
  userId: string;
  plan: Plan;
  paymentType: 'stripe' | 'paypal';
};

const getPurchaseSubscriptionOptions = (config: PurchaseOptionsConfigType) => {
  const {
    billingId,
    userId,
    plan: { paypalPlanKey, mainPlanKey, trialDuration, introPlanKey },
    paymentType,
  } = config;

  return {
    app: process.env.REACT_APP_NAME_APP || '',
    billing_id: billingId,
    billing_type: paymentType,
    customer_id: userId,
    paypal: {
      app: process.env.REACT_APP_NAME_APP || '',
      customer_id: userId,
      plan: paypalPlanKey || '',
    },
    stripe: {
      customer_id: userId,
      main_plan_key: mainPlanKey || '',
      ...(trialDuration ? { trial_duration: trialDuration, intro_plan_key: introPlanKey } : {}),
    },
  };
};

const getStipeElementsOptions = (plan: Plan): StripeElementsOptions => ({
  mode: 'subscription',
  amount: Number(plan.trialPrice) || Number(plan.price),
  currency: plan.currency?.toLowerCase() || 'usd',
  fonts: [
    {
      cssSrc: 'https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300..700&display=swap',
    },
  ],
});

const getPaymentRequestOptions = (plan: Plan): PaymentRequestOptions => ({
  country: 'US',
  currency: plan.currency?.toLowerCase() || 'usd',
  total: {
    label: process.env.REACT_APP_NAME_APP || '',
    amount: Number(plan.trialPrice) || Number(plan.price),
  },
  requestPayerName: true,
  requestPayerEmail: true,
});

export { getPurchaseSubscriptionOptions, getStipeElementsOptions, getPaymentRequestOptions };
