import React, { FC, ReactNode, useEffect, useState } from 'react';
import { isEqual } from 'lodash';
import useQueryParams from 'src/hooks/use-query-params';
import { getLSValue, setLSValue } from 'src/utils/locale-storage';
import { UserLocalStorageData } from 'src/types/user';
import { USER_DATA } from 'src/constants/common';
import { webApiService } from 'src/services/web-api';
import { FullscreenLoader } from 'src/components/fullscreen-loader';

import { getAdsData, trackNewUser } from './helpers';

type Props = {
  children: ReactNode;
};

const UserTrackingMiddleware: FC<Props> = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);

  const { getQueryParams, updateQueryParams } = useQueryParams();

  const userLSData = getLSValue(USER_DATA, true) as UserLocalStorageData;

  useEffect(() => {
    (async () => {
      try {
        const queryParams = getQueryParams();
        const adsData: Record<string, string> = await getAdsData(queryParams);

        // check if user data is the same as previously stored
        if (isEqual(userLSData?.ads_data || {}, adsData) && userLSData?.user_id) {
          if (!queryParams?.customer_id) {
            updateQueryParams({ customer_id: userLSData.user_id });
          }
          return;
        }

        if (queryParams?.customer_id && typeof queryParams.customer_id === 'string') {
          try {
            const currentUser = await webApiService.getCustomerById(String(queryParams.customer_id));

            setLSValue(USER_DATA, {
              user_id: queryParams.customer_id,
              ads_data: adsData,
              ...(currentUser?.contactEmail ? { email: currentUser?.contactEmail } : {}),
            });
          } catch (e: unknown) {
            console.error('Error getting customer:', e);
            const newUser = await trackNewUser(adsData);
            setLSValue(USER_DATA, { user_id: newUser.customerId, ads_data: adsData });
            updateQueryParams({ customer_id: newUser.customerId });
          }
        } else {
          const newUser = await trackNewUser(adsData);
          setLSValue(USER_DATA, { user_id: newUser.customerId, ads_data: adsData });
          updateQueryParams({ customer_id: newUser.customerId });
        }
      } catch (e: unknown) {
        console.error('Failed to track user', e);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [getQueryParams, updateQueryParams, userLSData]);

  return isLoading ? <FullscreenLoader /> : <>{children}</>;
};

export { UserTrackingMiddleware };
