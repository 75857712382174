import amplitude from 'amplitude-js';

import { CENTS_IN_DOLLAR, USER_DATA } from '../constants/common';
import { useAnalytic } from '../contexts';
import { getLSValue } from '../utils/locale-storage';
import { UserLocalStorageData } from '../types/user';
import { Plan } from '../types/plan';

type PurchaseAnalytics = (data?: { value?: number; plan?: Plan }) => void;

const usePurchaseAnalytics = (): PurchaseAnalytics => {
  const { gtagEvent, sendAmplitudeEvent } = useAnalytic();

  return (data) => {
    const value = data?.value;

    const currentUserData = getLSValue(USER_DATA, true) as UserLocalStorageData;
    const plan = data?.plan || currentUserData?.plan;

    const eventData = {
      currency: currentUserData?.plan?.currency,
      value: value || (Number(plan?.trialPrice) || Number(plan?.price)) / CENTS_IN_DOLLAR,
    };

    gtagEvent({
      name: 'purchase',
      props: eventData,
    });

    sendAmplitudeEvent({
      name: 'fb_purchase',
      props: eventData,
    });

    const revenueEvent = new amplitude.Revenue()
      .setProductId(plan?.plan || currentUserData?.plan?.id || '')
      .setPrice(eventData.value)
      .setQuantity(1);

    amplitude.getInstance().logRevenueV2(revenueEvent);
  };
};

export { usePurchaseAnalytics };
