import { cookieManager } from 'src/services/cookies';
import { ipInfoService } from 'src/services/ip';
import { webApiService } from 'src/services/web-api';
import userDataApiService from 'src/services/web-api/user-data-api';

const FB_UTM_SOURCES = ['facebook', 'meta'];
const TIMEOUT_DELAY = 3000;

const getAdsData = async (queryParams: Record<string, unknown>): Promise<Record<string, string>> => {
  let adsData: Record<string, string> = {
    channel: (queryParams?.utm_source as string) || '',
    click_id: (queryParams?.clickid as string) || '',
  };

  if (FB_UTM_SOURCES.includes((queryParams?.utm_source as string)?.toLowerCase())) {
    try {
      const [fbpid, fbclid] = await Promise.all([
        cookieManager.waitForCookie('_fbp', TIMEOUT_DELAY),
        cookieManager.waitForCookie('_fbc', TIMEOUT_DELAY),
      ]);

      adsData = {
        channel: (queryParams?.utm_source as string) || '',
        pixel_id: process.env.REACT_APP_FACEBOOK_PIXEL_ID || '',
        fbc: fbclid || (queryParams.fbclid as string) || '',
        fbp: fbpid || (queryParams.fbp as string) || '',
      };
    } catch (error: unknown) {
      console.error('Failed to retrieve Facebook cookies:', error);
    }
  }

  return adsData;
};

const trackNewUser = async (adsData: Record<string, string>) => {
  try {
    const userIp = await ipInfoService.getInfo();
    const userAgent = window.navigator.userAgent;

    const newUser = await webApiService.createCustomer({
      install: 'web',
    });

    const collectedData = {
      event_name: 'page_load',
      web_app_id: 'imagin8',
      event_source_url: window.location.href,
      client_user_agent: userAgent,
      customer_id: newUser.customerId,
      ip: userIp.ip || '',
      country: userIp.country || '',
      zip: userIp.postal || '',
      city: userIp.city || '',
      ads_network_data: [adsData],
    };

    await userDataApiService.loadCustomerTracking(collectedData);

    return newUser;
  } catch (error: unknown) {
    console.error('Error tracking new user:', error);
    throw error;
  }
};

export { getAdsData, trackNewUser };
