import React, { createContext, useCallback, useContext, useState } from 'react';

import { Modal } from '../../components';

interface Props {
  component: React.FC<{ onClose: () => void }>;
  title?: string;
  onClose?: () => void;
  onShow?: () => void;
  disableBackdropClick?: boolean;
  backdropClassName?: string;
  viewType?: 'full' | 'top' | 'bottom' | 'center';
}

type ContextStateType = {
  handleOpenModal(data: Props): void;
};

export type ModalStateType = Props & { isOpen: boolean };

const initialModalState: ModalStateType[] = [];

const ModalContext = createContext<ContextStateType>({
  handleOpenModal: () => {},
});

type ModalContextProviderProps = {
  children?: React.ReactNode;
};

const ModalContextProvider: React.FC<ModalContextProviderProps> = ({ children }) => {
  const [states, setStates] = useState<ModalStateType[]>(initialModalState);

  const handleOpenModal = useCallback((modalProps: Props): void => {
    setStates((prevStates) => [...prevStates, { ...modalProps, isOpen: true }]);
  }, []);

  const handleClose = (index: number): void => {
    setStates((prevStates) => [
      ...prevStates.slice(0, index),
      { ...prevStates[index], isOpen: false },
      ...prevStates.slice(index + 1),
    ]);
  };

  return (
    <ModalContext.Provider
      value={{
        handleOpenModal,
      }}
    >
      {children}
      {states.map((state, index) => (
        <Modal
          key={index}
          isOpen={state.isOpen}
          onClose={() => handleClose(index)}
          disableBackdropClick={state.disableBackdropClick}
          backdropClassName={state.backdropClassName}
          viewType={state.viewType}
        >
          {state.component({
            onClose: () => handleClose(index),
          })}
        </Modal>
      ))}
    </ModalContext.Provider>
  );
};

const useModalContext = (): ContextStateType => {
  const modalContext = useContext<ContextStateType>(ModalContext);

  if (!modalContext) {
    throw Error('useModalContext hook should be wrapped by ModalContextProvider');
  }

  return modalContext;
};

export { ModalContextProvider, useModalContext };
