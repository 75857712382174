enum ErrorCodesGroup {
  DECLINE = 'decline',
  RETRYABLE = 'retryable',
  INSUFFICIENT_FUNDS = 'insufficient_funds',
  INCORRECT_DETAILS = 'incorrect_details',
}

const errorCodes: Record<string, ErrorCodesGroup> = {
  // decline
  call_issuer: ErrorCodesGroup.DECLINE,
  do_not_honor: ErrorCodesGroup.DECLINE,
  card_not_supported: ErrorCodesGroup.DECLINE,
  card_velocity_exceeded: ErrorCodesGroup.DECLINE,
  currency_not_supported: ErrorCodesGroup.DECLINE,
  do_not_try_again: ErrorCodesGroup.DECLINE,
  fraudulent: ErrorCodesGroup.DECLINE,
  generic_decline: ErrorCodesGroup.DECLINE,
  lost_card: ErrorCodesGroup.DECLINE,
  merchant_blacklist: ErrorCodesGroup.DECLINE,
  new_account_information_available: ErrorCodesGroup.DECLINE,
  no_action_taken: ErrorCodesGroup.DECLINE,
  invalid_account: ErrorCodesGroup.DECLINE,
  invalid_amount: ErrorCodesGroup.DECLINE,
  not_permitted: ErrorCodesGroup.DECLINE,
  pickup_card: ErrorCodesGroup.DECLINE,
  restricted_card: ErrorCodesGroup.DECLINE,
  revocation_of_all_authorizations: ErrorCodesGroup.DECLINE,
  revocation_of_authorization: ErrorCodesGroup.DECLINE,
  security_violation: ErrorCodesGroup.DECLINE,
  service_not_allowed: ErrorCodesGroup.DECLINE,
  stolen_card: ErrorCodesGroup.DECLINE,
  stop_payment_order: ErrorCodesGroup.DECLINE,
  transaction_not_allowed: ErrorCodesGroup.DECLINE,
  expired_card: ErrorCodesGroup.DECLINE,
  // retryable
  authentication_required: ErrorCodesGroup.RETRYABLE,
  approve_with_id: ErrorCodesGroup.RETRYABLE,
  issuer_not_available: ErrorCodesGroup.RETRYABLE,
  processing_error: ErrorCodesGroup.RETRYABLE,
  reenter_transaction: ErrorCodesGroup.RETRYABLE,
  try_again_later: ErrorCodesGroup.RETRYABLE,
  // insufficient_funds
  insufficient_funds: ErrorCodesGroup.INSUFFICIENT_FUNDS,
  // incorrect_details
  incorrect_number: ErrorCodesGroup.INCORRECT_DETAILS,
  incorrect_cvc: ErrorCodesGroup.INCORRECT_DETAILS,
  incorrect_pin: ErrorCodesGroup.INCORRECT_DETAILS,
  incorrect_zip: ErrorCodesGroup.INCORRECT_DETAILS,
  invalid_cvc: ErrorCodesGroup.INCORRECT_DETAILS,
  invalid_expiry_month: ErrorCodesGroup.INCORRECT_DETAILS,
  invalid_expiry_year: ErrorCodesGroup.INCORRECT_DETAILS,
  invalid_number: ErrorCodesGroup.INCORRECT_DETAILS,
  invalid_pin: ErrorCodesGroup.INCORRECT_DETAILS,
};

const getErrorCodeType = (errorCode: string, declineCode: string) =>
  errorCodes[declineCode || errorCode] || ErrorCodesGroup.DECLINE;

export { getErrorCodeType, ErrorCodesGroup };
