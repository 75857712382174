import React, { FC, ReactElement } from 'react';

import { ErrorCodesGroup } from '../../helpers/get-payment-error';

const errorMessages: Record<ErrorCodesGroup, ReactElement> = {
  [ErrorCodesGroup.DECLINE]: (
    <>
      Unfortunately, your payment was unsuccessful
      <br />
      Your issuing bank declined the payment
      <br />
      To resolve the issue, please contact your bank for more details.
      <br />
      Alternatively, you may use another available payment method.
    </>
  ),
  [ErrorCodesGroup.RETRYABLE]: (
    <>
      Unfortunately, your payment was unsuccessful
      <br />
      Your issuing bank declined the payment
      <br />
      We kindly ask you to reattempt the payment.
    </>
  ),
  [ErrorCodesGroup.INSUFFICIENT_FUNDS]: (
    <>
      Unfortunately, your payment was unsuccessful
      <br />
      Insufficient balance on your card
      <br />
      No worries! Please top up your current card or use a different payment method.
    </>
  ),
  [ErrorCodesGroup.INCORRECT_DETAILS]: (
    <>
      Unfortunately, your payment was unsuccessful
      <br />
      The payment was declined due to a billing issue
      <br />
      It appears that there might be errors in the information entered. Please take a moment to review your details
      carefully and ensure they are entered correctly.
      <br />
      Alternatively, you may use another available payment method.
    </>
  ),
};

type Props = {
  errorCodeType: ErrorCodesGroup;
};

const ErrorToast: FC<Props> = ({ errorCodeType }) => <div>{errorMessages[errorCodeType]}</div>;

export { ErrorToast };
